import { withStyles } from "@material-ui/core/styles";
import { theme } from "app/themes";
import { Checkbox, CheckboxProps } from "@material-ui/core";

export const CoreCheckbox = withStyles({
	root: {
		//override MUI
		"&:is(span)": {
			padding: "9px",
		},
		color: theme.palette.secondary.main,
		"&$checked": {
			color: theme.palette.secondary.main,
		},
	},
	checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
