import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	pickerRoot: {
		display: "flex",
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadiusPill,
		height: "54px",
		overflow: "hidden",
		"& > :first-child": {
			width: "40%",
		},
	},
	label: {
		borderRight: `1px solid ${theme.palette.divider}`,
		display: "flex",
		alignItems: "center",
		fontWeight: "bold",
		gap: "12px",
		padding: "8px 16px",
	},
	customInputRoot: {
		height: "100%",
		"& > div": {
			height: "100%",
			border: "none",
			placeItems: "center",
			paddingInline: "0px",
		},
	},
	customSelect: {
		"&:is(div)": {
			height: "100%",
		},
		borderLeft: `1px solid ${theme.palette.divider}`,
		"& > div": {
			height: "100%",
			border: "none",
			placeItems: "center",
			paddingInline: "8px",
			"& > div": {
				width: "100%",
			},
		},
	},
	inputsContainer: {
		display: "flex",
		flexGrow: 1,
		"& input": {
			fontWeight: "bold",
			textAlign: "center",
		},
	},
}));
