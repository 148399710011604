import { AnyAction, Reducer, combineReducers } from "redux";
import thunk from "redux-thunk";
import { ApplicationState, ReducersType } from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";

export function createReducerManager<
	InitialReducers extends Partial<Record<keyof ApplicationState, Reducer<any, AnyAction>>> = ReducersType
>(initialReducers: InitialReducers) {
	// Create an object which maps keys to reducers
	const reducers = { ...initialReducers };

	// Create the initial combinedReducer
	let combinedReducer = combineReducers(reducers);

	// An array which is used to delete state keys when reducers are removed
	let keysToRemove: string[] = [];

	return {
		getReducerMap: () => reducers,

		// The root reducer function exposed by this object
		// This will be passed to the store
		reduce: (...params: Parameters<typeof combinedReducer>) => {
			const [state, action] = params;
			// If any reducers have been removed, clean up their state first
			if (keysToRemove.length > 0 && state) {
				Object.assign(state, { ...state });
				for (let key of keysToRemove) {
					delete state[key];
				}
				keysToRemove = [];
			}

			// Delegate to the combined reducer
			return combinedReducer(state, action);
		},

		// Adds a new reducer with the specified key
		add: <S, A extends AnyAction>(key: keyof ApplicationState, reducer: Reducer<S, A>) => {
			if (!key || reducers[key]) return;

			// Add the reducer to the reducer mapping
			reducers[key as string] = reducer;

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		},

		// Removes a reducer with the specified key
		remove: (key: keyof ApplicationState) => {
			if (!key || !reducers[key]) {
				return;
			}

			// Remove it from the reducer mapping
			delete reducers[key];

			// Add the key to the list of keys to clean up
			keysToRemove.push(key);

			// Generate a new combined reducer
			combinedReducer = combineReducers(reducers);
		},
	};
}
export default function configureAppStore(reducers: any, initialState?: ApplicationState) {
	const middleware: [ThunkMiddlewareFor<ApplicationState>] = [thunk];

	return configureStore<ApplicationState>({
		reducer: reducers,
		preloadedState: initialState,
		middleware,
	});
}
