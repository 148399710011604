import { CSSProperties, ReactElement, useEffect, useLayoutEffect, useRef } from "react";
import { useStyles } from "./InputStyles";
import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { Controller, FieldName, FieldValues, UseFormMethods } from "react-hook-form";
import { getError, capitalize, getLabel, getIcon } from "./InputHelpers";
import { CoreCheckbox } from "./CoreCheckbox";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import { IdName } from "shared/models/HelperModels";
import useId from "shared/hooks/useId";
import { BaseInputProps } from "./BaseInputLayout";
import { OverflowToolTip, OverflowTooltipForwardRefProps } from "../OverflowToopTip";

interface Props<
	ValueId extends string | number,
	T extends FieldValues,
	N extends FieldName<T>,
	Multiple extends boolean = false
> extends BaseInputProps<T, N> {
	placeholder?: string;
	values: { id: ValueId; name: string; hidden?: boolean; disabled?: boolean }[];
	disableNone?: boolean;
	width?: number;
	type?: string;
	onChange?: (selectedValue: Multiple extends true ? ValueId[] : ValueId) => void;
	multiple?: Multiple;
	asChips?: boolean;
	showLimited?: boolean;
	showSelectAll?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: SelectProps["MenuProps"] = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	variant: "menu",
};

export default function SelectWithValidation<
	ValueId extends string | number,
	T extends FieldValues,
	N extends FieldName<T>,
	Multiple extends boolean = false
>(props: Props<ValueId, T, N, Multiple>): ReactElement {
	const classes = useStyles(props);
	const selectAllId = useId();
	const labelForId = useId();
	const tooltipRef = useRef<OverflowTooltipForwardRefProps>(null);

	const inputContainerClasses = clsx(classes.inputContainer, {
		[classes.flexInput]: props.showLimited,
		[classes.redBorder]: Boolean(props.formHook.errors?.[`${props.name}`]),
	});

	useEffect(() => {
		if (!props.disabled && !props.readOnly) return;
		queueMicrotask(() => {
			tooltipRef.current?.compareSize();
		});
	}, [props.formHook.watch(props.name)]);

	return (
		<div className={clsx(classes.root, props.className)} style={props.styles}>
			<Grid container item alignItems="flex-end" className={inputContainerClasses}>
				{getIcon(props, classes)}

				<Grid item className={classes.flex1} style={{ overflow: "hidden" }}>
					<FormControl variant="standard" className={classes.select}>
						<InputLabel htmlFor={`label-${labelForId}`}>{getLabel(props)}</InputLabel>
						<Controller
							control={props.formHook.control}
							rules={props.validation}
							name={props.name}
							required
							defaultValue={props.defaultValue || props.multiple ? [] : ""}
							render={({ ref, onChange, value }) => {
								let areAllSelected = false;
								if (value instanceof Array) {
									areAllSelected = Boolean(
										props.values.map((pv) => pv.id).every((v) => value.includes(v)) &&
											value.filter((v) => !!v).length
									);
								}
								const handleSelectAll = (e) => {
									if (!props.multiple) return;
									const newSelected = areAllSelected ? [] : props.values.map((v) => v.id);

									const values = props.formHook.getValues() as any;
									props.formHook.reset({
										...values,
										//!Um yea, nested fields are not supported
										[props.name as any]: newSelected,
									});
									//@ts-ignore
									props.onChange?.(newSelected);
								};
								const selectAllText = areAllSelected ? "Deselect all" : "Select all";
								return (
									<OverflowToolTip
										ref={tooltipRef}
										title="TEXT_CONTENT_OF_REF"
										disabled={!props.disabled && !props.readOnly}
									>
										{({ ref: tooltipRef }) => (
											<Select
												variant="standard"
												MenuProps={MenuProps}
												multiple={props.multiple}
												labelId={`label-${labelForId}`}
												id={`label-${labelForId}`}
												label={`${capitalize(props.name)}${
													props.validation?.required ? "*" : ""
												}`}
												ref={(el) => {
													if (!(el instanceof HTMLElement)) return;
													const elementWithTextInIt = el.querySelector(
														":not(input,svg):not(:empty)"
													);
													if (!(elementWithTextInIt instanceof HTMLElement))
														return;

													if (!(el instanceof HTMLElement)) return;
													tooltipRef.current = elementWithTextInIt;
												}}
												inputRef={ref}
												required={true}
												className={classes.select}
												onChange={(e, child: any) => {
													//this is needed because select all button cannot stop this from firing with wrong data
													if (child?.props?.id === selectAllId) return;

													onChange(e.target.value);
													if (props.onChange) {
														props.onChange(e.target.value);
													}
												}}
												value={value ?? ""}
												disableUnderline
												renderValue={(selected: any) => {
													if (!props.multiple)
														return (
															props.values
																?.filter((v) => !v?.hidden)
																.find((x) => x.id === selected)?.name || ""
														);

													if (props.asChips) {
														return (
															<div className={classes.chips}>
																{selected
																	.filter((id) => {
																		let foundIndex =
																			props.values.findIndex(
																				(v) =>
																					v.id === id &&
																					!v?.hidden
																			);
																		return foundIndex > -1;
																	})
																	.map(
																		(id: number | string) =>
																			props.values
																				?.filter((v) => !v?.hidden)
																				.find((x) => x.id === id)
																				?.name || ""
																	)
																	.map((name: string) => {
																		if (name !== "")
																			return (
																				<Chip
																					key={`select-name-${name}`}
																					label={name}
																					className={classes.chip}
																				/>
																			);
																	})}
															</div>
														);
													}

													return selected
														.filter((id) => {
															let foundIndex = props.values.findIndex(
																(v) => v.id === id && !v?.hidden
															);
															return foundIndex > -1;
														})
														.map(
															(id: number | string) =>
																props.values
																	.filter((v) => !v?.hidden)
																	.find((x) => x.id == id)?.name
														)
														.join(", ");
												}}
												inputProps={{
													disabled: props.disabled,
													style: {
														paddingBottom: 4,
													},
												}}
												style={{ opacity: props.disabled ? 0.56 : 1 }}
											>
												{props.showSelectAll && (
													<Button
														className={classes.selectAllButton}
														onClick={handleSelectAll}
														id={selectAllId}
													>
														{selectAllText}
													</Button>
												)}
												{!props.disableNone && (
													<MenuItem value="" className={classes.menuItem}>
														<em>None</em>
													</MenuItem>
												)}
												{props.values
													.filter((v) => !v?.hidden)
													.map((value: any) => {
														return (
															<MenuItem
																key={props.name + value.id}
																value={value.id}
																disabled={value.disabled || false}
																className={classes.menuItem}
															>
																{props.multiple && (
																	<CoreCheckbox
																		checked={
																			(
																				(props.formHook.watch(
																					props.name
																				) as IdName[]) ?? []
																			).findIndex(
																				(x) => x === value.id
																			) > -1
																		}
																	/>
																)}
																<span className={classes.menuItemText}>
																	{value.name}
																</span>
															</MenuItem>
														);
													})}
											</Select>
										)}
									</OverflowToolTip>
								);
							}}
						/>
					</FormControl>
				</Grid>

				{!props.hideError && <div className={classes.error}>{getError(props)}</div>}
			</Grid>
		</div>
	);
}
