import { Guid } from "shared/models/HelperModels";
import { v4 as uuidv4 } from "uuid";

export function newGuid(): ReturnType<typeof crypto.randomUUID> {
	return uuidv4();
}

export const isValidGuid = (guid: Guid) => {
	var patternGuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
	return patternGuidRegex.test(guid);
};
