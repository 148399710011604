import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@mui/material";
import { isMobile } from 'react-device-detect';


export function useScreenBreakPoints() {

	return {
		isMobile,
	};
}
