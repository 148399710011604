import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { BaseInputProps } from "./BaseInputLayout";

export const getError = (props: BaseInputProps<any, string>): string | null => {
	if (props.formHook.errors[`${props.name}`]?.type === "manual")
		return props.formHook.errors[`${props.name}`]?.message || "";

	/// Custom back-end error
	const customBackendError = props.customErrors?.[`${capitalize(props.name)}`]?.[0];
	if (customBackendError) {
		return customBackendError;
	}

	const customFrontendError = props.customErrors?.[props.name]?.[0];
	if (customFrontendError) {
		return customFrontendError;
	}

	/// Front-end validation
	if (props.formHook.errors[`${props.name}`]?.type === "required")
		return `${capitalize(props.label ?? props.name)} is required`;
	if (props.formHook.errors[`${props.name}`]?.type === "pattern")
		return `${capitalize(props.label ?? props.name)} is invalid`;
	if (props.formHook.errors[`${props.name}`]?.type === "maxLength")
		return ` ${capitalize(props.label ?? props.name)} exceends limit of ${
			props.validation?.maxLength
		} characters`;
	if (props.formHook.errors[`${props.name}`]?.type === "minLength")
		return `${capitalize(props.label ?? props.name)} must have at least ${
			props.validation?.minLength
		} characters`;
	return null;
};

export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLabel = (props: BaseInputProps<any, string>): ReactNode | undefined => {
	let label = "";
	if (!props.label) label += capitalize(props.name);
	else if (props.label !== "NONE") label += capitalize(props.label);

	if (props.validation?.required) label += "*";

	return label;
};

export const getIcon = (props: BaseInputProps<any, any>, classes: Record<string, string>) => {
	if (!props.icon) return;
	return (
		<Grid style={{ height: "100%", paddingTop: 16 }} item className={classes.iconRoot}>
			{props.icon}
		</Grid>
	);
};
