import Layout from "./Layout";
import { RenderComponentSubroutes, Routes } from "./routes/routeHelpers";
import { useEffect, useRef, useState } from "react";
import { handleRefreshTimerOnRefreshPage } from "./auth/authService";
import { ApplicationState } from "./rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "./auth/UserRoles";
import { decreasePendingRequests, increasePendingRequests } from "shared/components/Toast/toastSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMsal } from "@azure/msal-react";
import { refreshTokenSilently } from "./auth/refreshTokenSilently";
import { resetFilter } from "features/panels/Audit/slices/processesSlice";
import { useEffectWithAbort } from "shared/hooks/useEffectWithAbort";
import { useScreenBreakPoints } from "shared/hooks/useIsMobile";

export default () => {
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.auth);
	const [userRoutes, setUserRoutes] = useState<Routes>([]);
	const previousRoleRef = useRef<UserRoles>();
	const { instance } = useMsal();
	const { isMobile } = useScreenBreakPoints();

	useEffect(() => {
		if (userRoutes.length)
			dispatchEvent(
				new CustomEvent("routes-loadded", {
					detail: {
						userRoutes,
					},
				})
			);
	}, [userRoutes]);

	useEffect(() => {
		handleRefreshTimerOnRefreshPage(user);
	}, []);

	useEffect(() => {
		if (!user.tokenExpiresOn || user.authType !== "microsoft") return;

		const NOW = new Date();
		const timeUntilExpiration = user.tokenExpiresOn - NOW.getTime();
		const MINUTE = 60 * 1000;
		const MICROSOFT_MIN_EXPIRATION_TIME = 5 * MINUTE;

		//if response doesnt change, try again in 20 seconds
		const MIN_TIMEOUT_VALUE = 20000;
		const timeout = Math.max(MIN_TIMEOUT_VALUE, timeUntilExpiration - MICROSOFT_MIN_EXPIRATION_TIME);
		const timeoutHandler = setTimeout(() => {
			refreshTokenSilently(instance, user.email, dispatch);
		}, timeout);

		return () => {
			clearTimeout(timeoutHandler);
		};
	}, [user.tokenExpiresOn]);

	useEffectWithAbort(
		(signal) => {
			if (user.userRole == undefined) {
				dispatch(increasePendingRequests());
				import(`./routes/byRole/none`).then((data) => {
					if (signal.aborted) return;
					setUserRoutes(data.routes);
					dispatch(decreasePendingRequests());
				});
			} else {
				const userRoleString = UserRoles[user.userRole].toString().toLocaleLowerCase();
				dispatch(increasePendingRequests());
				import(`./routes/byRole/${userRoleString}.tsx`).then((data) => {
					if (signal.aborted) return;
					setUserRoutes(data.routes);
					dispatch(decreasePendingRequests());
					if (user.userRole !== UserRoles.Audit) dispatch(resetFilter());
				});
			}

			return () => {
				previousRoleRef.current = user.userRole;
			};
		},
		[user.userRole]
	);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<Layout>{RenderComponentSubroutes(userRoutes)}</Layout>
		</LocalizationProvider>
	);
};
