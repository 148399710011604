import { makeStyles } from "@material-ui/core/styles";
import { BaseInputProps } from "./BaseInputLayout";

export const useStyles = makeStyles((theme) => ({
	root: {
		height: "fit-content",
		width: (props: any) => props.width || "100%",
		display: (props: any) => {
			if (props.invisible) return "none";
		},
	},
	errorMessage: {
		color: theme.palette.error.main,
	},
	iconRoot: {
		"& svg": {
			fill: theme.palette.common.black,
		},
	},
	inputContainer: (props: Partial<BaseInputProps<any, any>>) => ({
		width: (props as any).width || "100%",
		willChange: "border-color",
		transition: "border-color .4s",
		border: `1px solid ${theme.palette.divider}`,
		padding: "2px 16px",
		background: theme.palette.common.white,
		borderRadius: 30,
		'& svg:not([data-testid="ArrowDropDownIcon"])': {
			height: "29px",
		},
		display: "grid",
		gridTemplateColumns: props.icon ? "30px auto" : "auto",
	}),
	flexInput: {
		display: "flex !important",
		gap: "6px",
		flexWrap: "nowrap",
		alignItems: "start",
		height: "70px",
		"& $flex1": {
			textOverflow: "ellipsis",
			overflow: "hidden",
		},
	},
	input: {
		background: theme.palette.common.white,
		appearance: "none",
		border: "none",
		width: "100%",
		marginLeft: 12,
		"&:focus": {
			outline: "none",
		},
	},
	underline: {
		"&&&:before": {
			borderBottom: "none",
		},
		"&&:after": {
			borderBottom: "none",
		},
	},
	iconContainer: {
		padding: "8px !important",
	},
	redBorder: {
		"&:is(div)": {
			borderColor: theme.palette.error.main,
		},
	},
	flex1: {
		flex: 1,
	},
	textField: {
		width: "100%",
	},
	error: {
		minHeight: "18px",
		gridColumn: (props: Partial<BaseInputProps<any, any>>) => (props.icon ? 2 : 1),
		fontSize: 12,
		color: theme.palette.error.main,
	},
	select: (props: Partial<BaseInputProps<any, any>>) => ({
		width: "100%",
		"&:focus": {
			background: theme.palette.customColors.gray.primary,
		},
		padding: props.label === "NONE" ? "2px 4px" : "",
		marginTop: props.label === "NONE" ? "0px !important" : "",
	}),
	autocomplete: {
		width: "100%",
		"&:focus": {
			background: theme.palette.customColors.gray.primary,
		},
		minHeight: 50,
		paddingBlock: "6px",
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	switchRoot: (props: any) => ({
		cursor: "pointer",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: props.noBackground ? "2px 6px" : "2px 16px",
		background: props.noBackground ? "none" : theme.palette.common.white,
		borderRadius: "2023px",
		border: props.noBackground ? "none" : `1px solid ${theme.palette.divider}`,
		"& svg": {
			height: 29,
		},
		userSelect: "none",
	}),
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	selectAllButton: {
		//override MUI
		"&:is(button)": {
			display: "block",
			fontSize: "12px",
			textTransform: "none",
			color: theme.palette.primary.main,
			width: "14ch",
			margin: "0px 0px 4px 4px",
		},
	},
	menuItem: {
		"&:is(li)": {
			display: "flex",
			width: "100%",
			justifyContent: "start",
			padding: "6px",
		},
	},
	menuItemText: {
		flexGrow: 1,
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	autocompleteButton: {
		fontSize: "14px !important",
		padding: "4px 8px !important",
		borderRadius: "5px !important",
		backgroundColor: theme.palette.grey[100],
		marginInline: "5px !important",
		position: "relative",
		top: 10,
		transition: "background-color 0.3s",
		"&:hover": {
			backgroundColor: `${theme.palette.grey[300]} !important `,
		},
	},
}));
