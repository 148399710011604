import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getAuthConfig } from "app/auth/authService";
import { getMsalConfig } from "authconfig";
import { ReactElement, useState } from "react";
import { useEffectWithAbort } from "shared/hooks/useEffectWithAbort";

type Props = { children: (isInitialized: boolean) => ReactElement };
export function MicrosoftLoginProvider({ children }: Props) {
	const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null);

	useEffectWithAbort((signal, ifNotAbortedThen) => {
		getLocalMsalInstance().then(
			ifNotAbortedThen((msalInstance) => {
				setMsalInstance(msalInstance);
			})
		);
	}, []);

	if (!msalInstance) return <>{children(false)}</>;
	return <MsalProvider instance={msalInstance}>{children(true)}</MsalProvider>;
}

const config: {
	msalInstance?: IPublicClientApplication;
	isLoading?: Promise<IPublicClientApplication>;
} = {};
export async function getLocalMsalInstance(): Promise<IPublicClientApplication> {
	if (config.msalInstance) return config.msalInstance;

	// in case this method is called multiple times while it is fetching
	if (config.isLoading) return config.isLoading;
	try {
		config.isLoading = getNewMsalInstance();
		const instance = await config.isLoading;
		config.msalInstance = instance;
		return instance;
	} finally {
		config.isLoading = undefined;
	}
}

async function getNewMsalInstance(): Promise<IPublicClientApplication> {
	const base64EncodedAppConfig = await getAuthConfig(window.appConfig["BASE-API-URL"]);
	const strConfig = window.atob(base64EncodedAppConfig);
	const authConfig = JSON.parse(strConfig);

	window.appConfig = { ...window.appConfig, ...authConfig };
	const msalInstance = new PublicClientApplication(getMsalConfig());
	return msalInstance;
}
