import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProcessTemplateFilterTeamLead } from "features/panels/Admin/ViewProcessTemplates/ViewProcessTemplates";
import { Filter, OrderType } from "shared/models/Filters";
import { MakeNullableFields } from "shared/models/UtilityTypes";
import { objectKeys } from "shared/utils/objectKeys";

export type FilterContextNames = "teamlead-processes"; // further extends this in the future
export type IFilterState = Record<FilterContextNames, Filter> & {
	"teamlead-processes": MakeNullableFields<ProcessTemplateFilterTeamLead, "teamIds">;
};

const INITIAL_STATE: IFilterState = {
	["teamlead-processes"]: {
		keyword: "",
		pageNumber: 0,
		orderType: OrderType.ASC,
		processTypes: [],
		teamIds: null,
	},
};

export const filtersSlice = createSlice({
	name: "filters",
	initialState: INITIAL_STATE,
	reducers: {
		clearAllFilters(state) {
			const keys = objectKeys(state);
			keys.forEach((k) => {
				state[k] = INITIAL_STATE[k];
			});
		},
		clearFilter(
			state,
			{ payload: { contextName } }: PayloadAction<{ contextName: FilterContextNames }>
		) {
			state[contextName] = INITIAL_STATE[contextName];
		},
		setFilter<Ctx extends FilterContextNames>(
			state,
			{
				payload: { contextName, filter },
			}: PayloadAction<{ contextName: Ctx; filter: IFilterState[Ctx] }>
		) {
			state[contextName] = filter;
		},
		applyPartialFilter<Ctx extends FilterContextNames>(
			state,
			{
				payload: { contextName, filter },
			}: PayloadAction<{ contextName: Ctx; filter: Partial<IFilterState[Ctx]> }>
		) {
			state[contextName] = { ...(state[contextName] ?? {}), ...filter };
		},
		applyPartialFilterUpdate<Ctx extends FilterContextNames>(
			state,
			{
				payload: { contextName, filterUpdate },
			}: PayloadAction<{
				contextName: Ctx;
				filterUpdate(state: IFilterState[Ctx]): Partial<IFilterState[Ctx]>;
			}>
		) {
			state[contextName] = { ...(state[contextName] ?? {}), ...filterUpdate(state[contextName]) };
		},
	},
});

export const { clearAllFilters, clearFilter, setFilter, applyPartialFilter, applyPartialFilterUpdate } =
	filtersSlice.actions;
