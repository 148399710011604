import { Guid, Primitive, StringNum } from "shared/models/HelperModels";
import { ArrayKeys, CompletePath } from "shared/models/UtilityTypes";
import { deepCopyFrom } from "shared/services/deepCopyFrom";

declare global {
	interface Array<T> {
		distinct(): T[];
		distinctBy(predicate: (t: T) => T[keyof T]): T[];
		getIds(): T extends { id: Guid } ? Guid[] : never;
		findById(id: Guid | StringNum | undefined | null): T | undefined;
		intersect<T2 extends Primitive>(otherArr: Array<T2>): T2[];
		exclude<T2 extends Primitive>(otherArr: Array<T2>): T2[];
		getRandomElement(): T | undefined;
		last(): T;
		isEmpty(): boolean;
		max(): T extends number ? number | null : never;
		sortBy<Keys extends Array<T extends any[] ? ArrayKeys<T> : keyof T>>(
			keys: Keys,
			ascending?: boolean
		): T[];
		//@ts-ignore
		groupBy<P extends CompletePath<T>>(path: P): Record<PathValue<T, P>, T[]>;
	}
}

const newPrototype = {
	...Array.prototype,
	distinct() {
		return [...new Set(this)];
	},
	distinctBy<T>(predicate: (t: T) => T[keyof T]): T[] {
		const uniqueKeys = [...new Set(this.map(predicate))];
		return uniqueKeys.map((k) => this.find((t) => predicate(t) === k));
	},
	getIds(): Guid[] {
		return this.map((x) => x.id).filter((id) => Boolean(id));
	},
	findById<T>(id: Guid | null): T | undefined {
		if (id === null) return undefined;
		return this.find((x) => x.id === id);
	},
	intersect<T extends Primitive>(otherArr: Array<T>): T[] {
		return this.filter((x) => otherArr.includes(x));
	},
	exclude<T extends Primitive>(otherArr: Array<T>): T[] {
		return this.filter((x) => !otherArr.includes(x));
	},
	getRandomElement() {
		if (!this.length) return undefined;
		const randomIndex = Date.now() % this.length;
		return this[randomIndex];
	},
	last() {
		return this[this.length - 1];
	},
	isEmpty() {
		return this.length === 0;
	},
	max() {
		if (!this.length) return null;
		return Math.max(...this);
	},
	sortBy<T extends object, Keys extends Array<T extends any[] ? ArrayKeys<T> : keyof T>>(
		keys: Keys,
		ascending: boolean = true
	) {
		return this.sort((item1: T, item2: T) => {
			const reversedKeys = [...keys].reverse();
			let key = reversedKeys.pop();
			const firstCond = ascending ? 1 : -1;
			const secondCond = ascending ? -1 : 1;

			while (key) {
				const areBothNumbers = typeof item1[key] === "number" && typeof item2[key] === "number";
				let secondItem: any = item2[key];
				let firstItem: any = item1[key];
				if (!areBothNumbers) {
					firstItem = (item1[key] ?? "").toString().toLowerCase();
					secondItem = (item2[key] ?? "").toString().toLowerCase();
				}

				if (firstItem > secondItem) return firstCond;
				if (firstItem < secondItem) return secondCond;
				key = reversedKeys.pop();
			}

			return 0;
		});
	},
	groupBy(path: string) {
		const output: any = {};
		this.forEach((el) => {
			const value: any = getNestedFieldValue(el);
			if (output[value] === undefined) output[value] = [el];
			else output[value].push(el);
		});
		function getNestedFieldValue(el) {
			const nestings = path.toString().split(".");
			return nestings.reduce((previous, property) => previous[property], deepCopyFrom(el) as object);
		}

		return output;
	},
};

export const setArrayPrototype = () => {
	Object.setPrototypeOf(Array.prototype, newPrototype);
};
