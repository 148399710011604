import { CircularProgress } from "@material-ui/core";
import { Suspense, type LazyExoticComponent } from "react";
import { Route as BrowserRoute, RouteProps, Switch } from "react-router";

const RenderLoading = () => {
	return <div>{/* <CircularProgress /> */}</div>;
};

export function RenderComponentSubroutes(routes: Routes) {
	if (!routes) return <></>;

	return (
		<Suspense fallback={RenderLoading()}>
			<Switch>
				{routes.map((route, i) => (
					<RouteWithSubRoutes key={`route-${i}`} {...route} />
				))}
			</Switch>
		</Suspense>
	);
}

export function RouteWithSubRoutes(route: RouteType) {
	return (
		<BrowserRoute
			path={route.path}
			exact={route.exact}
			render={(props) => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}

type RouteType = {
	component: LazyExoticComponent<any>;
	path: Exclude<RouteProps["path"], undefined>;
	routes?: Routes;
	exact?: RouteProps["exact"];
};
export type Routes = RouteType[];
