import { makeStyles } from "@material-ui/core";
import Toast from "../shared/components/Toast/Toast";
import { useScreenBreakPoints } from "shared/hooks/useIsMobile";

export default (props: { children?: React.ReactNode }) => {
	const { isMobile } = useScreenBreakPoints();
	const classes = useLayoutStyles({ isMobile });

	return (
		<section className={classes.section}>
			{props.children}
			<Toast />
		</section>
	);
};

const useLayoutStyles = makeStyles((theme) => ({
	section: {
		height: (isMobile) => (isMobile ? "100%" : "100vh"),
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
	},
	"@media print": {
		section: {
			height: "initial",
		},
	},
}));
