import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import InputWithValidation from "../Inputs/InputWithValidation";
import SelectWithValidation from "../Inputs/SelectWithValidation";
import { useStyles } from "./PickerStyles";
import { Controller, UseFormMethods, useForm } from "react-hook-form";
import DescriptionDialog from "features/panels/Practice/ContinueProcess/DescriptionDialog";
import { DAYS_IN_MONTH, DAYS_IN_WEEK } from "shared/constants/time";

interface Props {
	name: string;
	label: string;
	formHook: UseFormMethods<any>;
	disabled?: boolean;
	defaultDays?: number;
	icon?: ReactElement;
	allowOff?: boolean;
	dialogContent?: InfoDialog;
	style?: CSSProperties;
}

interface InfoDialog {
	text: string;
	icon?: ReactElement;
}

export const OPTIONS = {
	OFF: {
		id: 0,
		name: "Off",
	},
	DAYS: {
		id: 1,
		name: "Days",
	},
	WEEKS: {
		id: DAYS_IN_WEEK,
		name: "Weeks",
	},
	MONTHS: {
		id: DAYS_IN_MONTH,
		name: "Months",
	},
} as const;
type OptionId = (typeof OPTIONS)[keyof typeof OPTIONS]["id"];
export default function DayPicker({
	formHook,
	label,
	name,
	disabled,
	defaultDays,
	icon,
	allowOff,
	dialogContent,
	style,
}: Props): ReactElement {
	const classes = useStyles();
	const inputName = `${name}-input`;
	const formatName = `${name}-format`;
	const initialRenderRef = useRef<boolean>(true);

	const dropdownValues = Object.values(OPTIONS).filter(({ id }) => id !== OPTIONS.OFF.id || allowOff);
	const formatFormHook = useForm();
	const MIN_VALUE = 0;
	const MAX_VALUE = 100;
	const EMPTY_INPUT = "-";

	useEffect(() => {
		const inputValue = formatFormHook.getValues(inputName);
		if (formatFormHook.getValues(formatName) === OPTIONS.OFF.id) {
			formatFormHook.setValue(inputName, EMPTY_INPUT);
			formHook.setValue(name, OPTIONS.OFF.id);
			return;
		}
		if (inputValue < MIN_VALUE) formatFormHook.setValue(inputName, MIN_VALUE);
		if (inputValue > MAX_VALUE) formatFormHook.setValue(inputName, MAX_VALUE);
		if (inputValue === EMPTY_INPUT) formatFormHook.setValue(inputName, OPTIONS.DAYS.id);
		formHook.setValue(name, formatFormHook.getValues(inputName) * formatFormHook.getValues(formatName));
	}, [formatFormHook.watch(inputName), formatFormHook.watch(formatName)]);

	useEffect(() => {
		if (initialRenderRef.current) {
			initialRenderRef.current = false;
			const days = defaultDays ?? 0;
			const optionId = getOptionId(days);
			const computedWeeksOrMonths = days / optionId;
			formatFormHook.setValue(
				inputName,
				optionId > OPTIONS.DAYS.id ? computedWeeksOrMonths : days || EMPTY_INPUT
			);
			formatFormHook.setValue(formatName, optionId);
			return;
		}
		const daysValue = formHook.getValues(name);
		if (daysValue === OPTIONS.OFF.id) {
			formatFormHook.setValue(inputName, EMPTY_INPUT);
			formatFormHook.setValue(formatName, OPTIONS.OFF.id);
			return;
		}
		if (daysValue % OPTIONS.MONTHS.id === 0) {
			formatFormHook.setValue(inputName, daysValue / OPTIONS.MONTHS.id);
			formatFormHook.setValue(formatName, OPTIONS.MONTHS.id);
			return;
		}
		if (daysValue % OPTIONS.WEEKS.id === 0) {
			formatFormHook.setValue(inputName, daysValue / OPTIONS.WEEKS.id);
			formatFormHook.setValue(formatName, OPTIONS.WEEKS.id);
			return;
		}
		formatFormHook.setValue(inputName, daysValue);
		formatFormHook.setValue(formatName, OPTIONS.DAYS.id);
	}, [formHook.watch("reset")]);

	const selectedFormatName = formatFormHook.watch(formatName);
	const isOffSelected = selectedFormatName === OPTIONS.OFF.id;

	function getOptionId(days: number): OptionId {
		if (days === 0) return OPTIONS.OFF.id;
		if (days % OPTIONS.MONTHS.id === 0) return OPTIONS.MONTHS.id;
		if (days % OPTIONS.WEEKS.id === 0) return OPTIONS.WEEKS.id;
		return OPTIONS.DAYS.id;
	}

	return (
		<div className={classes.pickerRoot} style={style}>
			<div className={classes.label}>
				<span>{icon}</span>
				<span>{label}</span>
				<div>
					{dialogContent && (
						<DescriptionDialog
							title={label}
							description={dialogContent.text}
							opener={dialogContent.icon}
						/>
					)}
				</div>
			</div>
			<div className={classes.inputsContainer} style={{ alignItems: "center" }}>
				<Controller
					name={name}
					control={formHook.control}
					defaultValue={defaultDays ?? 0}
					render={() => (
						<>
							<InputWithValidation
								formHook={formatFormHook}
								name={inputName}
								label="NONE"
								width={64}
								type={isOffSelected ? "text" : "number"}
								inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
								disabled={disabled || (allowOff && isOffSelected)}
								className={classes.customInputRoot}
								hideError
							/>
							<SelectWithValidation
								formHook={formatFormHook}
								name={formatName}
								label="NONE"
								values={dropdownValues}
								disableNone={true}
								disabled={disabled}
								defaultValue={OPTIONS.OFF.id}
								className={classes.customSelect}
								hideError
							/>
						</>
					)}
				/>
			</div>
		</div>
	);
}
