import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type ToastStyle = "error" | "warning" | "info" | "success";

export type IToastState = {
	open: boolean;
	customTimeoutSeconds?: number;
	message: string;
	style: ToastStyle;
	requestsPending: number;
	filesRequestsPending: number;
	disable?: boolean;
};

const INITIAL_STATE: IToastState = {
	open: false,
	message: "",
	style: "success",
	requestsPending: 0,
	filesRequestsPending: 0,
	disable: false,
};

export const toastSlice = createSlice({
	name: "toast",
	initialState: INITIAL_STATE,
	reducers: {
		setStyle: (state, action: { payload: { style: ToastStyle } }) => {
			state.style = action.payload.style;
		},
		show: (
			state,
			action: {
				payload: {
					message: string | any;
					style?: ToastStyle;
					customTimeoutSeconds?: number;
				};
			}
		) => {
			state.message = action.payload.message;
			state.style = action.payload.style || "success";
			state.customTimeoutSeconds = action.payload.customTimeoutSeconds;
			state.open = true;
			state.disable = false;
		},
		hide: (state) => {
			state.open = false;
		},
		increasePendingRequests: (state) => {
			state.requestsPending++;
		},
		disable: (
			state,
			action: {
				payload: boolean;
			}
		) => {
			state.disable = action.payload;
		},
		increaseFilesPendingRequests: (state) => {
			state.filesRequestsPending++;
		},
		decreasePendingRequests: (state) => {
			state.requestsPending = Math.max(0, state.requestsPending - 1);
		},
		decreaseFilesPendingRequests: (state) => {
			state.filesRequestsPending = Math.max(0, state.filesRequestsPending - 1);
		},
	},
});

export const {
	hide,
	show,
	setStyle,
	increasePendingRequests,
	increaseFilesPendingRequests,
	decreasePendingRequests,
	decreaseFilesPendingRequests,
	disable,
} = toastSlice.actions;
