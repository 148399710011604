export const SECONDS_IN_MINUTE = 60;

export const HOURS_IN_DAY = 24;

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;

export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;

export const SECOND_MS = 1000;
export const SECOND = 1;

export const MINUTE = SECONDS_IN_MINUTE * SECOND;
export const MINUTE_MS = SECONDS_IN_MINUTE * SECOND_MS;

export const HOUR = MINUTES_IN_HOUR * MINUTE;
export const HOUR_MS = MINUTES_IN_HOUR * MINUTE_MS;

export const DAY = HOURS_IN_DAY * HOUR;
export const DAY_MS = HOURS_IN_DAY * HOUR_MS;

export const WEEK = DAYS_IN_WEEK * DAY;
export const WEEK_MS = DAYS_IN_WEEK * DAY_MS;
