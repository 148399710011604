import { IProcessState } from "features/panels/Audit/slices/processesSlice";
import { Filter, OrderType } from "./Filters";

export type Guid = string;

export type IdName = {
	id: string;
	name: string;
};

export type DateInterval = {
	startDate: string;
	endDate: string;
} | null;

export const INITIAL_FILTER: Filter = {
	keyword: "",
	orderType: OrderType.ASC,
	pageNumber: 0,
};

export const INITIAL_PROCESS_FILTER: IProcessState["filter"] = {
	...INITIAL_FILTER,
	practiceIds: null, //default value until it is populated by the first  practice fetch
	teamIds: null,
	betweenDates: null,
	owners: [],
	states: [],
	templateGroupId: "",
	processTypes: [],
	quizStatus: [],
};

export type ParamsId = { id: string };
export type CheckListItem = IdName & { checked: boolean };
export type AnyString = string & { _anyString?: never };

/**
 * Specifies what structure (T) a string has if it is JSON.parsed
 */
export type JSONString<T> = string & { _json?: T };

export type StringNum = string | number;
export type Primitive = string | number | symbol | boolean | null | undefined;
