import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
	name: "search",
	initialState: "",
	reducers: {
		setKeyword: (state, action) => {
			state = action.payload;
			return state;
		},
		clearKeyword: (state, action) => {
			state = "";
			return state;
		},
	},
});

export const { setKeyword } = searchSlice.actions;
