import { DependencyList, useEffect, EffectCallback } from "react";

export const useEffectWithAbort = (
	effect: (
		signal: AbortSignal,
		ifNotAbortedThen: <T>(callback: (res: T) => void) => (res: T) => void
	) => ReturnType<EffectCallback>,
	deps: DependencyList = []
) => {
	useEffect(() => {
		const controller = new AbortController();

		function ifNotAbortedThen<T>(callback: (res: T) => void): (res: T) => void {
			return (res) => {
				if (controller.signal.aborted) return;
				return callback(res);
			};
		}
		const returnedFunction = effect(controller.signal, ifNotAbortedThen);

		return () => {
			controller.abort();
			returnedFunction?.();
		};
	}, deps);
};
