import authReducer, { IAuthState } from "./auth/authSlice";
import { IToastState, toastSlice } from "../shared/components/Toast/toastSlice";
import storageSession from "redux-persist/lib/storage/session";
import { PersistConfig, persistReducer } from "redux-persist";
import { searchSlice } from "../shared/slices/searchSlice";
import { sidebarSlice, ISidebarState } from "../shared/slices/sidebarSlice";
import { IProcessState, processesSlice } from "features/panels/Audit/slices/processesSlice";
import { Action, Reducer } from "@reduxjs/toolkit";
import { ProcessTemplateSliceType, processTemplateSlice } from "shared/slices/processTemplateSlice";
import { ProcessSliceType, processSlice } from "shared/slices/processSlice";
import { IFilterState, filtersSlice } from "shared/slices/filtersSlice";

// The top-level state object
export type ApplicationState = {
	auth: IAuthState;
	search: string;
	sidebar: ISidebarState;
	processes: IProcessState;
	toast: IToastState;
	processTemplate: ProcessTemplateSliceType;
	process: ProcessSliceType;
	filters: IFilterState;
};

const getPersistConfig = (key: string): PersistConfig<any> => ({
	key,
	storage: storageSession,
});

type ReducersMap = {
	[Key in keyof ApplicationState]: Reducer<ApplicationState[Key], Action<any>>;
};
export const reducers: ReducersMap = {
	auth: persistReducer(getPersistConfig("root"), authReducer),
	search: searchSlice.reducer,
	toast: toastSlice.reducer,
	processTemplate: processTemplateSlice.reducer,
	process: processSlice.reducer,
	sidebar: persistReducer(getPersistConfig("sidebar"), sidebarSlice.reducer),
	processes: persistReducer(getPersistConfig("processes"), processesSlice.reducer),
	filters: persistReducer(getPersistConfig("filters"), filtersSlice.reducer),
};
export type ReducersType = typeof reducers;

export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
