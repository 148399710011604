import { CSSProperties, ReactElement, ReactNode, useEffect, useState } from "react";
import CoreButton from "./Buttons/CoreButton";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles } from "@material-ui/core";
import useId from "shared/hooks/useId";
import { theme } from "app/themes";

interface Props {
	title: ReactNode;
	renderPopupOpener?(): void;
	renderContent(): ReactNode;
	handleConfirm?(): void;
	handleCancel?(): void;
	open?: boolean;
	setOpen?: (newValue: boolean) => void;
	renderCustomActionButtons?: () => ReactNode;
	minWidth?: number;
	maxWidth?: string;
	style?: CSSProperties;
	overflow?: CSSProperties["overflow"];
}

export default function CustomPopup(props: Props): ReactElement {
	const [internalOpen, setInternalOpen] = useState(false);

	useEffect(() => {
		if (props.open !== undefined) {
			setInternalOpen(props.open);
		}
	}, [props.open]);

	const popupDescriptionId = useId();
	const popupTitleId = useId();
	const { dialogActions } = useStyles();

	const handleCancel = () => {
		if (props.handleCancel) {
			props.handleCancel();
			return;
		}
		setOpen(false);
	};

	const handleConfirm = () => {
		props.handleConfirm?.();
	};

	const setOpen = (newValue: boolean) => {
		if (props.setOpen) props.setOpen(newValue);
		else setInternalOpen(newValue);
	};

	const renderActionButtons = () => {
		if (props.renderCustomActionButtons) return props.renderCustomActionButtons();

		return (
			<>
				<CoreButton
					variant="secondary"
					text="Cancel"
					width="12ch"
					height="56px"
					onClick={handleCancel}
				/>
				<CoreButton text="Confirm" width="12ch" height="56px" onClick={handleConfirm} />
			</>
		);
	};

	return (
		<>
			{props.renderPopupOpener && (
				<div onClick={() => setOpen(true)} style={{ cursor: "pointer", display: "contents" }}>
					{props.renderPopupOpener()}
				</div>
			)}
			<Dialog
				disableEnforceFocus
				open={internalOpen}
				onClose={handleCancel}
				aria-labelledby={popupTitleId}
				aria-describedby={popupDescriptionId}
				PaperProps={{
					style: {
						borderRadius: "16px",
						padding: 4,
						minWidth: props.minWidth || 400,
						maxWidth: props.maxWidth ?? 500,
						...props?.style,
					},
				}}
			>
				<DialogTitle id={popupTitleId}>
					<div style={{ fontWeight: "bold", display: "contents" }}>{props.title}</div>
				</DialogTitle>
				<Divider
					orientation="horizontal"
					style={{
						backgroundColor: theme.palette.grey[600],
					}}
				/>
				<DialogContent
					style={{
						overflow: props.overflow,
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
						paddingTop: "16px",
					}}
					id={popupDescriptionId}
				>
					{props.renderContent()}
				</DialogContent>
				<DialogActions className={dialogActions}>{renderActionButtons()}</DialogActions>
			</Dialog>
		</>
	);
}

const useStyles = makeStyles({
	dialogActions: {
		maxHeight: "90px",
		display: "flex",
		flexGrow: 1,
		paddingInline: "24px", //match the contents padding
		justifyContent: "space-between",
		"& button:only-of-type": {
			width: "100%",
		},
	},
});
