import { Dispatch } from "react";
import { InteractionRequiredAuthError, IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { loginRequest } from "../../authconfig";
import { logout, setTokens } from "./authSlice";
export const refreshTokenSilently = async (
	instance: IPublicClientApplication,
	email: string,
	dispatch: Dispatch<any>,
	forceRefresh: boolean = false
) => {
	const currentAccount = instance.getAccountByUsername(email) ?? undefined;
	const silentRequest: SilentRequest = {
		scopes: loginRequest().scopes,
		account: currentAccount,
		forceRefresh,
	};

	await instance
		.acquireTokenSilent(silentRequest)
		.then((result) => {
			dispatch(
				setTokens({
					accessToken: result.accessToken,
					expiresOn: result.expiresOn?.getTime(),
					refreshToken: "",
					type: "microsoft",
				})
			);
		})
		.catch(async (error) => {
			if (error instanceof InteractionRequiredAuthError) {
				return await instance
					.acquireTokenPopup(silentRequest)
					.then((result) => {
						dispatch(
							setTokens({
								accessToken: result.accessToken,
								expiresOn: result.expiresOn?.getTime(),
								refreshToken: "",
								type: "microsoft",
							})
						);
					})
					.catch((error) => {
						dispatch(logout);
						window.location.href = "/";
					});
			}
		});
};
