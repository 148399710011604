import { disable, hide, show, ToastStyle } from "../components/Toast/toastSlice";
import store from "index";

export const showToast = (
	message: string,
	style: ToastStyle = "success",
	customTimeoutSeconds?: number
) => {
	store.dispatch(show({ message: message, style, customTimeoutSeconds }));
};

export const hideToast = () => {
	store.dispatch(hide());
};

export const disableToast = (disableToast: boolean) => store.dispatch(disable(disableToast));
