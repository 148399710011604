import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IUserCredentials } from "shared/models/IUserCredentials";
import { ILoginCredentials } from "shared/models/ILoginCredentials";
import { UserRoles } from "./UserRoles";
import jwtDecode from "jwt-decode";
import { UserPermission } from "shared/enums/UserPermissions";
import { isMobile } from "react-device-detect";
import { createURLSearchParams } from "shared/utils/searchParams";
import { Guid } from "shared/models/HelperModels";
import { showToast } from "shared/services/toastService";

export type IAuthState = IUserCredentials & {
	hasUserSeenReleaseNotes: boolean;
	teamIds: Guid[];
	isPasswordLoginDisabled: boolean;
	organizationDomainRestrictions: string[];
};
const INITIAL_STATE: IAuthState = {
	userId: "",
	isLoggedIn: false,
	email: "",
	userRole: undefined,
	allRoles: [],
	firstName: "",
	lastName: "",
	accessToken: "",
	refreshToken: "",
	organizationId: undefined,
	organizationName: undefined,
	practiceId: undefined,
	practiceName: undefined,
	permissions: [],
	tokenExpiresOn: undefined,
	authType: "local",
	hasUserSeenReleaseNotes: false,
	teamIds: [],
	isPasswordLoginDisabled: false,
	organizationDomainRestrictions: [],
};

export const microsoftAuthLogin = createAsyncThunk("auth/mslogin", async (code: string | null) => {
	let url = "api/Authenticate/currentUser";

	if (!!code) {
		const urlParams = `?${createURLSearchParams({ code }).toString()}`;
		url = url + urlParams;
	}
	try {
		const { data } = await axios.get<ILoginResponse>(url);
		return data;
	} catch (e: any) {
		const firstError = e.response.data.errors?.["Toast"]?.[0];

		// why is this here? because in dispatch().catch() i dont have access to e.response.data
		if (firstError) showToast(firstError, "error");
		else showToast("User was not found", "error");
		throw e;
	}
});

export const localAuthLogin = createAsyncThunk(
	"auth/login",
	(userCredentials: ILoginCredentials, thunkAPI) => thunkHandler(postLogin(userCredentials), thunkAPI)
);

const postLogin = (payload: ILoginCredentials) =>
	axios.post<ILoginResponse>("api/Authenticate/login", payload);

export const logout = createAsyncThunk("auth/logout", async () => {
	const response = await axios.post("api/Authenticate/logout");
	return response.data;
});

export const thunkHandler = async (asyncFn, thunkAPI) => {
	try {
		const response = await asyncFn;
		return response.data;
	} catch (error) {
		const response = (error as AxiosError).response;
		return thunkAPI.rejectWithValue(response?.data);
	}
};

const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		setTokens: (
			state,
			{
				payload: { accessToken, refreshToken, type, expiresOn },
			}: PayloadAction<IRefreshTokenCredentials>
		) => {
			state.accessToken = accessToken;
			if (expiresOn) state.tokenExpiresOn = expiresOn;
			else {
				const decoded = jwtDecode(accessToken);
				if (
					typeof decoded === "object" &&
					decoded &&
					"exp" in decoded &&
					typeof decoded.exp === "number"
				)
					state.tokenExpiresOn = decoded.exp * 1000;
			}
			state.refreshToken = refreshToken;
			state.authType = type;
		},
		setName: (
			state,
			action: {
				payload: {
					firstName: string;
					lastName: string;
				};
			}
		) => {
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
		},
		setRole: (state, action: { payload: { role: UserRoles } }) => {
			state.userRole = action.payload.role;
		},
		setRoles(state, { payload: { roles } }: PayloadAction<{ roles: UserRoles[] }>) {
			state.allRoles = roles.sort();
			if (state.userRole && !roles.includes(state.userRole))
				state.userRole = Math.min(...state.allRoles);
		},
		setOrganization: (
			state,
			action: PayloadAction<{
				organizationId: string;
				organizationName: string;
				isPasswordLoginDisabled?: boolean;
			}>
		) => {
			state.organizationId = action.payload.organizationId;
			state.organizationName = action.payload.organizationName;
			if (action.payload.isPasswordLoginDisabled !== undefined)
				state.isPasswordLoginDisabled = action.payload.isPasswordLoginDisabled;
		},
		setOrganizationDomainRestrictions: (state, action: PayloadAction<{ domains: string[] }>) => {
			state.organizationDomainRestrictions = action.payload.domains;
		},
		setPractice: (state, action: { payload: { practiceId: string } }) => {
			state.practiceId = action.payload.practiceId;
		},
		setTeams(state, action: PayloadAction<{ teamIds: Guid[] }>) {
			state.teamIds = action.payload.teamIds.filter(Boolean);
		},
		markReleaseNotesAsSeen(state, action: PayloadAction<void>) {
			state.hasUserSeenReleaseNotes = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(localAuthLogin.fulfilled, (state, action: PayloadAction<ILoginResponse>) => {
			state.userId = action.payload.userId;
			state.isLoggedIn = true;
			state.email = action.payload.email;
			state.userRole =
				isMobile && action.payload.allRoles.includes(UserRoles.PracticeUser)
					? UserRoles.PracticeUser
					: parseInt(action.payload.userRole);
			state.allRoles = [...action.payload.allRoles].sort((a, b) => a - b);
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.organizationId = action.payload.organizationId;
			state.organizationName = action.payload.organizationName;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.authType = "local";
			state.practiceId = action.payload.practiceId;
			state.practiceName = action.payload.practiceName;
			state.permissions = action.payload.permissions;
			state.hasUserSeenReleaseNotes = action.payload.hasUserSeenReleaseNotes;
			state.teamIds = action.payload.teamIds;
			state.isPasswordLoginDisabled = action.payload.isPasswordLoginDisabled;
			state.organizationDomainRestrictions = action.payload.organizationDomainRestrictions;
		});
		builder.addCase(microsoftAuthLogin.fulfilled, (state, action: PayloadAction<ILoginResponse>) => {
			state.userId = action.payload.userId;
			state.isLoggedIn = true;
			state.email = action.payload.email;
			state.userRole =
				isMobile && action.payload.allRoles.includes(UserRoles.PracticeUser)
					? UserRoles.PracticeUser
					: parseInt(action.payload.userRole);
			state.allRoles = [...action.payload.allRoles].sort((a, b) => a - b);
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.organizationId = action.payload.organizationId;
			state.organizationName = action.payload.organizationName;
			state.practiceId = action.payload.practiceId;
			state.practiceName = action.payload.practiceName;
			state.permissions = action.payload.permissions;
			state.hasUserSeenReleaseNotes = action.payload.hasUserSeenReleaseNotes;
			state.teamIds = action.payload.teamIds;
			state.isPasswordLoginDisabled = action.payload.isPasswordLoginDisabled;
			state.organizationDomainRestrictions = action.payload.organizationDomainRestrictions;
		});
		builder.addCase(
			logout.fulfilled,
			(state, action: PayloadAction<ILoginResponse>) => (state = INITIAL_STATE)
		);
		builder.addMatcher(
			//logout if Server doesnt respond to logout request
			(action: PayloadAction) => action.type === "auth/logout/rejected",
			(state, action: PayloadAction<ILoginResponse>) => (state = INITIAL_STATE)
		);
	},
});

interface IRefreshTokenCredentials {
	accessToken: string;
	refreshToken: string;
	expiresOn?: number;
	type: "microsoft" | "local";
}

export type ILoginResponse = {
	userId: string;
	email: string;
	firstName: string;
	lastName: string;
	userRole: string;
	allRoles: UserRoles[];
	accessToken: string;
	refreshToken: string;
	organizationId?: string;
	organizationName?: string;
	isPasswordLoginDisabled: boolean;
	organizationDomainRestrictions: string[];
	practiceId?: string;
	practiceName?: string;
	permissions: UserPermission[];
	hasUserSeenReleaseNotes: boolean;
	redirect: ILoginRedirect;
	teamIds: Guid[];
	typesenseSearchKey: string;
};

export interface ILoginRedirect {
	url: string;
	info: string;
}

export const {
	setTokens,
	setName,
	setRole,
	setRoles,
	setOrganization,
	setPractice,
	markReleaseNotesAsSeen,
	setTeams,
	setOrganizationDomainRestrictions,
} = authSlice.actions;

export default authSlice.reducer;
