import {
	defaultProcessTemplate,
	setLoading,
	setProcessTemplateSteps as setProcessTemplateStepsRedux,
	updateProcessTemplateStep as updateProcessTemplateStepRedux,
	updateProcessTemplate as updateProcessTemplateRedux,
	addProcessTemplateStep as addProcessTemplateStepRedux,
	setProcessTemplateQuizzes as setProcessTemplateQuizzesRedux,
	updateProcessTemplateQuiz as updateProcessTemplateQuizRedux,
	addProcessTemplateQuiz as addProcessTemplateQuizRedux,
	addProcessTemplateQuestion as addProcessTemplateQuestionRedux,
	updateProcessTemplateQuestion as updateProcessTemplateQuestionRedux,
	addProcessTemplateOption as addProcessTemplateOptionRedux,
	updateProcessTemplateOption as updateProcessTemplateOptionRedux,
	removeProcessTemplateQuestion as removeProcessTemplateQuestionRedux,
	removeProcessTemplateOption as removeProcessTemplateOptionRedux,
} from "shared/slices/processTemplateSlice";
import { ProcessTemplate, Step } from "shared/models/ProcessTemplate";
import store from "index";
import { Quiz, QuizQuestion, QuizQuestionOption } from "shared/models/Quiz";

export const updateProcessTemplate = (payload: Partial<ProcessTemplate>) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	store.dispatch(updateProcessTemplateRedux({ ...payload, modifiedBy: firstName + " " + lastName }));
};

export const updateProcessTemplateApprovals = (
	payload: Partial<Pick<ProcessTemplate, "approvers" | "approversHistory" | "modifiedOn">>
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;
	const processTemplate = store.getState().processTemplate;

	const newApproversState: Partial<ProcessTemplate> = {
		approvers: payload.approvers,
		approversHistory: [...(payload?.approversHistory ?? []), ...processTemplate.approversHistory],
		modifiedOn: payload.modifiedOn,
	};

	store.dispatch(
		updateProcessTemplateRedux({ ...newApproversState, modifiedBy: firstName + " " + lastName })
	);
};

export const updateLoadingState = (payload: boolean) => {
	store.dispatch(setLoading(payload));
};

export const resetProcessTemplate = () => {
	store.dispatch(defaultProcessTemplate());
};

/// STEPS
export const setProcessTemplateSteps = (payload: Step[], modifiedOn: Date | string) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		steps: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(setProcessTemplateStepsRedux(data));
};

export enum UpdateProperty {
	Attachments = "attachments",
	LinkedSOP = "linkedSOP",
	Title = "title",
	Description = "description",
	Skipable = "skipable",
	EndStep = "endStep",
	IsConditional = "isconditional",
	LinkedSteps = "linked steps",
	LinkedOptions = "linked options",
	Links = "links",
	Role = "role",
}

export const updateProcessTemplateStep = (
	payload: Partial<Step>,
	modifiedOn: Date | string,
	updateProperty: UpdateProperty
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		step: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
		property: updateProperty,
	};

	store.dispatch(updateProcessTemplateStepRedux(data));
};

export const addProcessTemplateStep = (payload: Step, modifiedOn: Date | string) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		step: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(addProcessTemplateStepRedux(data));
};

/// QUIZZES
export const setProcessTemplateQuizzes = (payload: Quiz[], modifiedOn: Date | string) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		quizzes: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(setProcessTemplateQuizzesRedux(data));
};

export const updateProcessTemplateQuiz = (payload: Partial<Quiz>, modifiedOn: Date | string) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		quiz: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(updateProcessTemplateQuizRedux(data));
};

export const addProcessTemplateQuiz = (payload: Quiz, modifiedOn: Date | string) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		quiz: payload,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(addProcessTemplateQuizRedux(data));
};

/// QUESTIONS
export const addProcessTemplateQuestion = (
	payload: Partial<QuizQuestion>,
	quizId: string,
	modifiedOn: Date | string
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		question: payload,
		quizId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(addProcessTemplateQuestionRedux(data));
};

export const updateProcessTemplateQuestion = (
	payload: Partial<QuizQuestion>,
	quizId: string,
	modifiedOn: Date | string
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		question: payload,
		quizId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(updateProcessTemplateQuestionRedux(data));
};

/// OPTIONS
export const addProcessTemplateOption = (
	payload: Partial<QuizQuestionOption>,
	questionId: string,
	modifiedOn: Date | string
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		option: payload,
		questionId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(addProcessTemplateOptionRedux(data));
};

export const updateProcessTemplateOption = (
	payload: Pick<QuizQuestionOption, "id" | "title" | "isCorrect" >,
	questionId: string,
	modifiedOn: Date | string,
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		option: payload,
		questionId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(updateProcessTemplateOptionRedux(data));
};

export const removeProcessTemplateQuestion = (
	questionId: string,
	quizId: string,
	modifiedOn: Date | string
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		questionId,
		quizId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(removeProcessTemplateQuestionRedux(data));
};

export const removeProcessTemplateOption = (
	optionId: string,
	questionId: string,
	modifiedOn: Date | string
) => {
	const firstName = store.getState().auth.firstName;
	const lastName = store.getState().auth.lastName;

	const data = {
		optionId,
		questionId,
		modifiedData: { modifiedOn, modifiedBy: firstName + " " + lastName },
	};

	store.dispatch(removeProcessTemplateOptionRedux(data));
};
