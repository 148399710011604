import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createPalette" {
	interface Palette extends ExtraColors {}
	interface PaletteOptions extends ExtraColors {}
}
declare module "@material-ui/core/styles/createTheme" {
	interface ThemeOptions extends Partial<ExtraThemeConfig> {}
	interface Theme extends ExtraThemeConfig {}
}
declare module "@material-ui/core/styles/shape" {
	interface Shape extends ExtraShapes {}
}

type ExtraShapes = {
	borderRadiusPill: string;
};
type ExtraThemeConfig = {
	components: {
		headerHeight: string | number;
	};
};
type ExtraColors = {
	customColors: {
		blue: {
			primary: string;
			secondary: string;
		};
		gray: {
			primary: string;
			secondary: string;
			third: string;
			dark: string;
		};
	};
	ternary: {
		main: string;
	};
};

export const theme = createTheme({
	palette: {
		primary: {
			main: "#5656e2",
		},
		secondary: {
			main: "#3eb583",
		},
		ternary: {
			main: " #f37595",
		},
		customColors: {
			blue: {
				primary: "rgba(8,27,51,0.8)",
				secondary: "rgba(8,27,51,0.5)",
			},
			gray: {
				primary: "#f5f5f5",
				third: "rgba(8,27,51,0.05)",
				secondary: " rgba(0, 0, 0, 0.08)",
				dark: "#555555", //darkest gray from the palette in figma
			},
		},
		warning: {
			main: "#FFB644",
		},
		background: {
			paper: "#fff",
		},
		common: {
			white: "#fff",
		},
		action: {
			hover: "#F3F4F5",
		},
		error: {
			main: "#f37595",
		},
		divider: "#D4D4D4",
		text: {
			primary: "rgba(8,27,51,0.8)",
		},
	},
	shape: {
		borderRadius: 14,
		borderRadiusPill: "2023px",
	},
	components: {
		headerHeight: "84px",
	},
});
