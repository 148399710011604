import { useEffect, useRef, useState } from "react";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useDispatch, useSelector } from "react-redux";
import { hide, show } from "./toastSlice";
import MuiAlert from "@mui/material/Alert";
import { Snackbar, makeStyles } from "@material-ui/core";
import { ApplicationState } from "app/rootReducer";
import { theme } from "app/themes";
import { SECOND_MS } from "shared/constants/time";

const useStyles = makeStyles((theme) => ({
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	error: {
		backgroundColor: theme.palette.error.main,
	},
	success: {
		backgroundColor: theme.palette.secondary.main,
	},
}));

function SlideTransition(props: TransitionProps) {
	return <Slide {...props} direction="up" />;
}

const DEFAULT_TIMEOUT_SECONDS = 2.5;
export default function TransitionsSnackbar() {
	const classes = useStyles();
	const {
		open: isOpen,
		message: toastMessage,
		requestsPending,
		style: toastStyle,
		customTimeoutSeconds,
		disable,
	} = useSelector((state: ApplicationState) => state.toast);
	const dispatch = useDispatch();
	const requestTimer = useRef<NodeJS.Timeout | null>(null);
	const ShowTimer = useRef<NodeJS.Timeout | null>(null);
	const [transitionState] = useState<{
		Transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>;
	}>({
		Transition: SlideTransition,
	});

	useEffect(() => {
		if (isOpen == true && disable !== true) {
			if (ShowTimer.current) {
				clearTimeout(ShowTimer.current);
			}
			ShowTimer.current = setTimeout(() => {
				dispatch(hide());
			}, (customTimeoutSeconds ?? DEFAULT_TIMEOUT_SECONDS) * SECOND_MS);
		}
	}, [isOpen, toastStyle]);

	useEffect(() => {
		if (requestsPending > 0 && !isOpen && disable !== true) {
			requestTimer.current = setTimeout(() => {
				dispatch(
					show({
						message: "Loading...",
						style: "info",
					})
				);
			}, 300);
		} else {
			if (requestTimer.current) clearTimeout(requestTimer.current);
			requestTimer.current = null;
		}

		return () => {
			if (requestTimer.current) clearTimeout(requestTimer.current);
		};
	}, [requestsPending]);

	return (
		<Snackbar
			open={isOpen}
			TransitionComponent={transitionState.Transition}
			key={transitionState.Transition.name}
			autoHideDuration={3000}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
		>
			<MuiAlert
				icon={false}
				elevation={6}
				variant="filled"
				severity={toastStyle || "success"}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: 300,
					fontSize: 16,
					color: theme.palette.common.white,
					borderRadius: theme.shape.borderRadius,
				}}
				classes={{
					filledInfo: classes.info,
					filledSuccess: classes.success,
					filledError: classes.error,
				}}
				children={<span>{toastMessage}</span>}
			/>
		</Snackbar>
	);
}
