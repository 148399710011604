import React, { ReactElement, CSSProperties, ReactNode } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Button, Divider } from "@material-ui/core";
import { theme } from "app/themes";

export interface CoreButtonProps {
	width?: number | string;
	height?: number | string;
	text?: string;
	children?: ReactNode;
	onClick?(e: React.MouseEvent): void;
	styles?: CSSProperties;
	disabled?: boolean;
	icon?: ReactNode;
	variant?: "primary" | "secondary" | "ternary";
	className?: string;
	innerClassName?: string;
	color?: "primary" | "secondary" | "ternary";
}

const defaultHeight = "50px";
export const useStyles = makeStyles((theme) => ({
	root: ({
		disabled,
		variant = "primary",
		height,
		width,
		color = "primary",
	}: Pick<CoreButtonProps, "disabled" | "variant" | "height" | "width" | "color">) => ({
		color: variant === "primary" ? theme.palette.common.white : theme.palette[color].main,
		backgroundColor: variant === "primary" ? theme.palette[color].main : "transparent",
		height: height ?? defaultHeight,
		border: `1px solid ${variant !== "ternary" ? theme.palette[color].main : "transparent"}`,
		fontWeight: "bold",
		padding: "12px 24px",
		borderRadius: "2023px",
		display: "flex",
		textTransform: "capitalize",
		justifyContent: "center",
		alignItems: "center",
		willChange: "opacity,background-color",
		transition: "opacity .4s,background-color .4s",
		cursor: disabled ? "default" : "pointer",
		opacity: disabled ? 0.56 : 1,
		pointerEvents: disabled ? "none" : "auto",
		width: width ?? "auto",
		"&:is(button)": {
			minWidth: defaultHeight,
		},
		"&:hover": {
			backgroundColor:
				variant === "primary"
					? (theme.palette[color] as any).dark ?? theme.palette[color].main
					: theme.palette.grey[200],
		},
	}),
}));

export default function CoreButton({
	disabled = false,
	variant = "primary",
	...props
}: CoreButtonProps): ReactElement {
	const classes = useStyles({ disabled, variant, ...props });

	return (
		<Button
			className={`${classes.root} ${props.className ?? ""}`}
			style={props.styles}
			onClick={props.onClick}
			classes={{ label: props.innerClassName }}
		>
			{props.icon && <div>{props.icon}</div>}
			{props.text ?? props.children}
		</Button>
	);
}

CoreButton.Divider = ({ height }: Pick<CoreButtonProps, "height">) => {
	return (
		<Divider
			orientation="vertical"
			style={{ height: height ?? defaultHeight, backgroundColor: theme.palette.common.white }}
		/>
	);
};
