import axios, { AxiosError } from "axios";
import { getLocalMsalInstance } from "features/Login/MicrosoftLoginProvider";
import store, { AppConfig } from "index";
import {
	decreaseFilesPendingRequests,
	decreasePendingRequests,
	increaseFilesPendingRequests,
	increasePendingRequests,
} from "shared/components/Toast/toastSlice";
import { handleLogout } from "./auth/authService";
import { refreshTokenSilently } from "./auth/refreshTokenSilently";
import { ApplicationState } from "./rootReducer";

const requestsUrl = ["uploadImage", "uploadVideo", "uploadAudio"];
const isFileRequest = (url) => requestsUrl.some((substring) => url.includes(substring));

const interceptor = (appConfig: AppConfig) => {
	axios.interceptors.request.use(
		async (conf) => {
			let state = store.getState();
			store.dispatch(increasePendingRequests());

			if (isFileRequest(conf.url)) {
				store.dispatch(increaseFilesPendingRequests());
			}

			conf.baseURL = appConfig["BASE-API-URL"];

			let { accessToken, tokenExpiresOn, email, authType } = state.auth;
			const NOW = new Date();
			if (tokenExpiresOn && tokenExpiresOn < NOW.getTime() && authType === "microsoft") {
				const msalInstance = await getLocalMsalInstance();
				await refreshTokenSilently(msalInstance, email, store.dispatch);
				state = store.getState();
			}

			accessToken = state.auth.accessToken;
			if (accessToken) conf.headers["Authorization"] = `Bearer ${accessToken}`;
			return conf;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(next) => {
			store.dispatch(decreasePendingRequests());
			if (isFileRequest(next.config.url)) {
				store.dispatch(decreaseFilesPendingRequests());
			}
			return Promise.resolve(next);
		},
		(error: AxiosError) => {
			store.dispatch(decreasePendingRequests());
			if (isFileRequest(error.config?.url ?? "")) {
				store.dispatch(decreaseFilesPendingRequests());
			}
			if (error?.response?.status === 401) {
				const state: ApplicationState = store.getState();
				if (state.auth.isLoggedIn) {
					handleLogout();
				}
			}
			return Promise.reject(error);
		}
	);
};

export default {
	interceptor,
};
