import { Button } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { CoreButtonProps, useStyles } from "./CoreButton";

type Props = CoreButtonProps & {
	tooltipText: string;
};

const TooltipButton = ({ disabled = false, variant = "primary", tooltipText, ...props }: Props) => {
	const classes = useStyles({ disabled, variant, ...props });

	return (
		<Tooltip title={tooltipText}>
			<Button
				className={`${classes.root} ${props.className ?? ""}`}
				style={props.styles}
				onClick={props.onClick}
				classes={{ label: props.innerClassName }}
			>
				{props.icon && <div>{props.icon}</div>}
				{props.text ?? props.children}
			</Button>
		</Tooltip>
	);
};

export default TooltipButton;
