import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export interface ISidebarState {
	minified: boolean;
	numberOfInProgressProcessess: number;
}

const INITIAL_STATE: ISidebarState = {
	minified: false,
	numberOfInProgressProcessess: 0,
};

export const getProcessesInProgressCount = createAsyncThunk("sidebar/start", async () => {
	const { data } = await axios.get("api/Process/getNumberOfInProgress");
	return data;
});

export const sidebarSlice = createSlice({
	name: "sidebar",
	initialState: INITIAL_STATE,
	reducers: {
		setMinified: (state, action: PayloadAction<{ minified: boolean }>) => {
			state.minified = action.payload.minified;
		},
		increaseNumberOfProcessesInProgress: (state, action: PayloadAction<void>) => {
			state.numberOfInProgressProcessess++;
		},
		decreaseNumberOfProcessesInProgress: (state, action: PayloadAction<void>) => {
			state.numberOfInProgressProcessess = Math.max(0, state.numberOfInProgressProcessess - 1);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProcessesInProgressCount.fulfilled, (state, action: PayloadAction<number>) => {
			state.numberOfInProgressProcessess = action.payload;
		});
	},
});

export const { setMinified, decreaseNumberOfProcessesInProgress, increaseNumberOfProcessesInProgress } =
	sidebarSlice.actions;
