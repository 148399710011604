import Tooltip from "@material-ui/core/Tooltip";
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { SECOND_MS } from "shared/constants/time";
import { AnyString } from "shared/models/HelperModels";

type AnyElements = HTMLElementTagNameMap[keyof HTMLElementTagNameMap];
type Props = {
	title: AnyString | "TEXT_CONTENT_OF_REF";
	children: (data: { ref: React.MutableRefObject<AnyElements | null> }) => JSX.Element;
	disabled?: boolean;
};
export type OverflowTooltipForwardRefProps = {
	compareSize(): void;
};
export const OverflowToolTip = forwardRef(function (
	{ children, title, disabled }: Props,
	ref: React.ForwardedRef<OverflowTooltipForwardRefProps>
) {
	const textElementRef = useRef<AnyElements | null>(null);
	const [isHoverEnabled, setIsHoverEnabled] = useState(false);

	function compareSize() {
		if (!textElementRef.current) return;
		const isOverflowing = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
		setIsHoverEnabled(isOverflowing);
	}

	useImperativeHandle(
		ref,
		() => ({
			compareSize, // use forwardref in case the contents of your element might change
		}),
		[]
	);

	useLayoutEffect(() => {
		compareSize();
		window.addEventListener("resize", compareSize);

		return () => {
			window.removeEventListener("resize", compareSize);
		};
	}, []);

	function getTitle() {
		if (disabled) return "";
		if (title !== "TEXT_CONTENT_OF_REF") return title;
		const textContent = textElementRef.current?.textContent;
		if (!textContent) return "";
		return textContent;
	}

	return (
		<Tooltip
			title={getTitle()}
			interactive
			disableHoverListener={!isHoverEnabled || disabled}
			enterDelay={0.6 * SECOND_MS}
		>
			{children({ ref: textElementRef })}
		</Tooltip>
	);
});
