import "bootstrap/dist/css/bootstrap.css";
import "./custom.css";

import { ThemeProvider } from "@material-ui/core";
import { theme } from "app/themes";
import { getLocalMsalInstance } from "features/Login/MicrosoftLoginProvider";
import { createBrowserHistory } from "history";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, useHistory } from "react-router";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app/App";
import Interceptor from "./app/axiosInterceptor";
import { reducers } from "./app/rootReducer";
import configureStore, { createReducerManager } from "./app/store";
import { setPrototypes } from "shared/prototypes";
import { StyledEngineProvider } from "@mui/material";
import { registerLicense } from "@syncfusion/ej2-base";

setPrototypes();

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;

export const history: ReturnType<typeof useHistory> = createBrowserHistory({ basename: baseUrl });

export const reducerManager = createReducerManager(reducers);
const store = configureStore(reducerManager.reduce);
const persistor = persistStore(store);

void fetch("/envconfig.json")
	.then((res) => res.json())
	.then(async (appConfig: AppConfig) => {
		window.appConfig = appConfig;
		registerLicense(appConfig.SYNCFUSION_LICENSE_KEY);

		// fetch SSO config in background
		void getLocalMsalInstance();
		Interceptor.interceptor(appConfig);

		ReactDOM.render(
			<StyledEngineProvider injectFirst>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<Router history={history}>
							<ThemeProvider theme={theme}>
								<App />
							</ThemeProvider>
						</Router>
					</PersistGate>
				</Provider>
			</StyledEngineProvider>,
			document.getElementById("root")
		);
	});

export type AppDispatch = typeof store.dispatch;
export default store;

export type AppConfig = {
	["BASE-API-URL"]: string;
	["SYNCFUSION_LICENSE_KEY"]: string;
	AuthAuthority: string;
	AuthClientId: string;
	LoginRequestScope: string;
};
declare global {
	interface Window {
		appConfig: AppConfig;
	}
}
