import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProcessState } from "shared/models/AuditProcess";
import { Process } from "shared/models/Process";
import { Step } from "shared/models/ProcessTemplate";

export type ProcessSliceType = Process;
const INITIAL_STATE: ProcessSliceType = {
	id: "",
	patientCode: "",
	patientCodeDate: "",
	patientRole: null,
	template: null,
	startDate: null,
	steps: [],
	currentTemplateStepId: "",
	signature: null,
	note: "",
	state: ProcessState.InProgress,
	parentProcessId: "",
	parentProcessTemplateStepId: "",
	attachmentsLog: [],
	policies: [],
	practiceId: "",
};

export const processSlice = createSlice({
	name: "process",
	initialState: INITIAL_STATE,
	reducers: {
		updateProcess: (
			state,
			action: PayloadAction<{ process: Partial<Process>; step: Partial<Step> }>
		) => {
			const updatedStep = action.payload.step;
			const updatedProcess = action.payload.process;
			return {
				...state,
				...updatedProcess,
				steps: state.steps.map((value) => {
					if (value.id === updatedStep.id) {
						return { ...value, ...updatedStep };
					}
					return value;
				}),
			};
		},
		setProcess: (state, action: PayloadAction<{ steps: Step[]; process: Partial<Process> }>) => {
			return { ...state, ...action.payload.process, steps: action.payload.steps };
		},
		getDefaultProcess: () => {
			return INITIAL_STATE;
		},
	},
});

export const { updateProcess, setProcess, getDefaultProcess } = processSlice.actions;
