import { Configuration, PopupRequest } from "@azure/msal-browser";

export const getMsalConfig = (): Configuration => ({
	auth: {
		clientId: window.appConfig.AuthClientId,
		// this should end with '/common' because we want to allow any user to login (personal + tenantId users)
		authority: window.appConfig.AuthAuthority,
		redirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
});

export const loginRequest = (): PopupRequest => ({ scopes: [window.appConfig.LoginRequestScope] });
