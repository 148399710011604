import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProcessesFilter } from "shared/models/Filters";
import { INITIAL_PROCESS_FILTER } from "shared/models/HelperModels";
import { deepCopyFrom } from "shared/services/deepCopyFrom";
import { ReplaceValueOfWith } from "shared/models/UtilityTypes";

export interface IChip {
	label: string;
	onDelete?(): void;
}

export interface IProcessState {
	filter: ReplaceValueOfWith<
		ProcessesFilter,
		"practiceIds" | "teamIds",
		ProcessesFilter["practiceIds"] | null | ProcessesFilter["teamIds"]
	>;
}

const INITIAL_STATE: IProcessState = {
	filter: INITIAL_PROCESS_FILTER,
};

export const processesSlice = createSlice({
	name: "processes",
	initialState: INITIAL_STATE,
	reducers: {
		resetFilter: (state, action: PayloadAction) => {
			state.filter = deepCopyFrom(INITIAL_PROCESS_FILTER);
		},
		resetFilterKey: (state, { payload }: PayloadAction<keyof IProcessState["filter"]>) => {
			state.filter[payload as any] = INITIAL_PROCESS_FILTER[payload];
		},
		applyToFilter: (state, { payload }: PayloadAction<Partial<IProcessState["filter"]>>) => {
			Object.assign(state.filter, payload);
			state.filter.pageNumber = INITIAL_PROCESS_FILTER.pageNumber;
		},
		increasePageNumber: (state, action: PayloadAction) => {
			state.filter.pageNumber++;
		},
	},
});

export const { applyToFilter, resetFilter, increasePageNumber, resetFilterKey } = processesSlice.actions;
