import React, {
	CSSProperties,
	HTMLInputTypeAttribute,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from "react";
import { useStyles } from "./InputStyles";
import { getError, capitalize, getLabel, getIcon } from "./InputHelpers";
import { Grid, TextField } from "@mui/material";
import { Controller, FieldName, FieldValues, RegisterOptions, UseFormMethods } from "react-hook-form";
import { InputBaseComponentProps } from "@material-ui/core";
import { BaseInputProps } from "./BaseInputLayout";
import { theme } from "app/themes";

interface Props<T extends FieldValues, N extends FieldName<T>> extends BaseInputProps<T, N> {
	placeholder?: string;
	manualErrorMessage?: string;
	width?: number;
	type?: HTMLInputTypeAttribute;
	invisible?: boolean;
	inputProps?: InputBaseComponentProps;
	onKeyUp?: (key: string) => void;
}

export default function InputWithValidation<T extends FieldValues, N extends FieldName<T>>(
	props: Props<T, N>
): ReactElement {
	const classes = useStyles(props);
	const [initialValue, setInitialValue] = useState(props.defaultValue);

	useEffect(() => {
		setInitialValue(initialValue);
	}, [props.defaultValue]);

	const errorMessage = getError(props);
	const getInputContainerClasses = () => {
		// Errors exist
		if (errorMessage) return `${classes.inputContainer} ${classes.redBorder}`;
		return `${classes.inputContainer}`;
	};

	return (
		<div className={classes.root + " " + (props.className ?? "")} style={props.styles}>
			<Grid container item alignItems="flex-end" className={getInputContainerClasses()}>
				{getIcon(props, classes)}
				<Grid item className={classes.flex1}>
					<Controller
						control={props.formHook.control}
						rules={props.validation}
						name={props.name}
						defaultValue={props.defaultValue ? props.defaultValue : ""}
						render={({ ref, onChange, value }) => (
							<TextField
								variant="standard"
								id={`input-${props.name}`}
								label={getLabel(props)}
								autoComplete="off"
								inputRef={ref}
								value={value}
								type={props.type ?? "text"}
								className={classes.textField}
								onChange={(e) => {
									onChange(e.target.value);
								}}
								onKeyUp={(event) => {
									props.onKeyUp?.(event.key);
								}}
								InputProps={{
									disableUnderline: true,
									disabled: props.disabled,
									readOnly: props.readOnly,
									inputProps: {
										...props.inputProps,
										style: {
											paddingBottom: 4,
										},
									},
								}}
							/>
						)}
					/>
				</Grid>
				{!props.hideError && <div className={classes.error}>{errorMessage}</div>}
			</Grid>
		</div>
	);
}
